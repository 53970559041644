// background: linear-gradient(45deg,  #002B64 0%,#0087D7 100%);
// $pt-border-radius: 20px;
$button-border-width: 1px;

$pt-font-family: "Lato",
                  -apple-system,
                 "BlinkMacSystemFont",
                 "Segoe UI",
                 "Roboto",
                 "Oxygen",
                 "Ubuntu",
                 "Cantarell",
                 "Open Sans",
                 "Helvetica Neue",
                 "Icons16", // support inline Palantir icons
                 sans-serif !default;

$blue-dark: #002B64;
$blue-middle: #0067AF;
$blue-light: #0087D7;