@import '../../theme.scss';

.link {
  display: inline-block;
  padding: 15px 30px;
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.3s;
  color: $blue-dark;

  &:hover {
    cursor: pointer;
  }

  &:active {
    background-color: $blue-light;
    border-color: $blue-light;
  }

  &:disabled,
  &[disabled] {
    background-color: #fff !important;
    opacity: 0.4;
  }
}

.white {
  color: #fff;

  &:hover {
    color: #fff;
  }
}
