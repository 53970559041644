@import '../../theme.scss';

.container {
  margin: 20px auto;
}

.header {
  font-weight: 300;
  font-size: 40px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 80px;
}

.card {
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  color: #333;
  padding: 60px 40px;
  margin: 0 -10px;
  color: $blue-dark;
}

.cardHeader {
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h2 {
  font-weight: 500;
  font-size: 26px;
  margin-top: 0;
}

.imageContainer {
  margin: 40px 20px;
  height: 200px;
  line-height: 200px;
  img {
    vertical-align: middle;
    max-width: 100%;
  }
}

.button {
  width: 100%;
  display: block;
}

.resetResultsButton {
  position: absolute;
  top: 14px;
  right: 10px;
}
.version {
  position: absolute;
  bottom: 5px; right: 5px;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
}