@import '../../theme.scss';

.button {
  display: inline-block;
  padding: 15px 30px;
  border-radius: 50px;
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.3s;
  border: 2px solid $blue-dark;
  color: $blue-dark;
  background-color: #fff;
  &:hover {
    background-color: $blue-dark;
    color: #fff;
    cursor: pointer;
  }
  &:active {
    background-color: $blue-light;
    border-color: $blue-light;
  }
  &:disabled,
  &[disabled] {
    background-color: #fff !important;
    opacity: 0.4;
  }
}

.white {
  border: 2px solid #fff;
  color: #fff;
  background: rgba(255,255,255, .10);

  &:hover {
    background: #fff;
    color: $blue-dark;
  }

  &:disabled,
  &[disabled] {
    background: rgba(255,255,255, .10) !important;
    opacity: 0.4;
    &:hover {
      background: rgba(255,255,255, .10) !important;
      color: #fff;
      cursor: not-allowed;
    }
  }
}

.white-transparent {
  border: 0;
  color: #fff;
  background-color: transparent;
  &:hover {
    background: #fff;
    color: $blue-dark;
  }
}