@import '../../theme.scss';

.container {
  margin: 20px;
}

.videoPlayer {
  text-align: center;
  video {
    margin-top: 70px;
  }
}

.navbar {
  background: linear-gradient(45deg,  #002B64 0%,#0087D7 100%);
  color: #fff;
  height: 80px;
  position: relative;
  text-align: center;
}

.navbarHeader {
  margin: 0;
  font-weight: 300;
  font-size: 26px;
  line-height: 80px;
}

.navbarBackButton {
  position: absolute;
  left: 10px;
  top: 14px;
}

.navbarSave {
  position: absolute;
  top: 14px;
  right: 10px;
}

.navbarSaveActive {
  animation-name: save-pulsate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  &:hover {
    animation: 0;
  }
}

@keyframes save-pulsate {
  0% {
    background: rgba(255,255,255, .10);
  }
  50% {
    background: rgba(255,255,255, .5);
  }
  100 {
    background: rgba(255,255,255, .10);
  }
}

/* result eval */

.resultColumn {
  text-align: center;
  padding-top: 160px;
}

.activityTime {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 50px;
}

.activityTimeActive {
  color: $blue-dark;
}

.activityButton {
  padding: 15px;
}

.activityButtonActive {
  background-color: $blue-dark;
  color: #fff;
}

.activityLabel {
  text-transform: uppercase;
  padding-top: 50px;
  font-size: 16px;
}

.activityLabelActive {
  color: $blue-dark;
}

/* toggle playback */

.togglePlayback {
  padding-top: 50px;
  text-align: center;
}

.togglePlaybackButton {
  padding-left: 80px;
  padding-right: 80px;
}

.togglePlaybackLabel {
  padding-top: 5px;
  text-transform: uppercase;
  color: #999;
}