// FIXME fix navbar style duplication
.navbar {
  background: linear-gradient(45deg,  #002B64 0%,#0087D7 100%);
  color: #fff;
  height: 80px;
  position: relative;
  text-align: center;
}

.navbarHeader {
  margin: 0;
  font-weight: 300;
  font-size: 26px;
  line-height: 80px;
}

.navbarBackButton {
  position: absolute;
  left: 10px;
  top: 14px;
}

.tableTextHeader {
  text-align: center;
  font-weight: 300;
  font-size: 28px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.table {
  margin: 20px auto;
  font-size: 16px !important;
  td, th {
    padding: 10px 30px !important;
  }
  th {
    text-transform: uppercase;
  }
}

.tableValue {
  text-align: right !important;
}

.tableVideoColumn {
  width: 240px;
}