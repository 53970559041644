@import '../../theme.scss';

.container {
  margin: 20px auto;
}

.navbarBackButton {
  position: absolute;
  left: 10px;
  top: 14px;
}

.header {
  font-weight: 300;
  font-size: 40px;
  text-align: center;
  margin-top: 160px;
  margin-bottom: 60px;
}

.subheader {
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  display: block;
  margin-bottom: 70px;
  text-transform: uppercase;
}

.card {
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  color: #333;
  padding: 60px 40px;
  margin: 0 auto;
  color: $blue-dark;
}

.cardHeader {
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h2 {
  font-weight: 500;
  font-size: 26px;
  margin-top: 0;
}

.imageContainer {
  margin: 40px 20px;
}

.button {
  width: 100%;
  display: block;
}

/* Progress */

.progress {
  width: 60%;
  margin: 0 auto;
  position: relative;
  height: 10px;
  background-color: #fff;
}

.point {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #fff;
  margin-left: -16px;
  margin-top: -11px;
}

.pointTick {
  background-color: $blue-middle;
  color: #fff;
  position: absolute;
  height: 28px;
  width: 28px;
  border-radius: 14px;
  top: 2px;
  left: 2px;
  padding-top: 6px;
  padding-left: 6px;
  z-index: 3;
}

.pointCurrent {
  border: 4px solid $blue-middle;
  background-color: #fff;
  color: #fff;
  position: absolute;
  height: 28px;
  width: 28px;
  border-radius: 14px;
  top: 2px;
  left: 2px;
  z-index: 3;
}

.bar {
  z-index: 2;
  background-color: $blue-middle;
  position: absolute;
  left: 0;
  height: 6px;
  top: 2px;
}

/* Summary */

.summaryContainer {
  margin-top: 80px;
  text-align: center;
}

.summaryHeader {
  font-weight: 300;
  font-size: 40px;
  text-align: center;
  margin-top: 120px;
  margin-bottom: 50px;
}

.summaryLabel {
  font-weight: 300;
  font-size: 10px;
  text-align: center;
  display: block;
  text-transform: uppercase;
  margin-top: 10px;
  color: rgba(255,255,255,0.6);
}
